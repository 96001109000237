import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 28%;
  padding: 1rem;
  ${ifProp('isHighlighted',
    css`
    background-color: ${prop('theme.color.blue', '#0F0F3A')};
    color: white;
    `,
    css`
    border-bottom: 1px solid ${prop('theme.color.blue', '#0F0F3A')};
    border-top: 1px solid ${prop('theme.color.blue', '#0F0F3A')};
    `)};

  &:hover {
    cursor: pointer;
  }
`;

const Plan = ({
  children, isSelected, onClick, className, data, selectedItem,
}) => (
  <Flex
    className={className}
    isHighlighted={isSelected}
    onClick={() => onClick({ ...selectedItem, [Object.keys(selectedItem)[0]]: data })}
  >
    {children}
  </Flex>
);

export default Plan;
