/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Survey from '../Survey';
import surveyData from '../../../static/surveys/signup.json';
import {
  fetchMyInfo, getMyInfo, updateMember, getIsFetchingMyInfo,
} from '../../redux/member';
import compressData from '../../lib/compressData';
import { transformSpecialitiesStringToID } from '../../../functions/utils/occupations';
import { memberLevelCheckBuilder } from '../../lib';
import expandMemberData from '../../lib/expandMemberData';
import FailedAction from '../FailedAction';
import { getAllSpecialities } from '../../redux/members';

const { fetchWAuth } = require('../../lib');

const SurveyContainer = styled.div``;

const Application = () => {
  const member = useSelector(getMyInfo);
  const allSpecialities = useSelector(getAllSpecialities);
  const cleanedUpSpecialities = allSpecialities.filter((sp) => sp.label !== '');
  const dispatch = useDispatch();
  const fetching = useSelector(getIsFetchingMyInfo);
  const [isFetching, setIsFetching] = useState(fetching);
  const id = member._id;

  const onSubmit = (surveyResults) => {
    window.scrollTo(0, 0);
    const data = compressData(surveyResults.data);
    let finalCompanySpecialities = '';
    data.companySpecialities.forEach((speciality) => {
      finalCompanySpecialities += `${transformSpecialitiesStringToID(speciality)},`;
    });

    if (data.otherSpecialities) {
      data.otherSpecialities.split(',').forEach((speciality) => {
        if (speciality !== '') { finalCompanySpecialities += `${transformSpecialitiesStringToID(speciality)},`; }
      });
    }
    data.companySpecialities = finalCompanySpecialities;
    delete data.otherSpecialities;

    data.membershipLevel = 'initial';
    dispatch(updateMember({ data, id, action: 'initial' }));
    fetchWAuth('/.netlify/functions/send-mail',
      {
        body: JSON.stringify({
          subject: 'New Pending Edits from ACT',
          text: `User ${member.fullName} from ${surveyResults.data.companyName} has request to become a member of Act`,
          html: '',
        }),
      });
  };

  useEffect(() => {
    setIsFetching(fetching);
  }, [fetching]);

  if (isFetching) {
    return <h1>Loading...</h1>;
  }

  if (!id) {
    return <FailedAction action={() => dispatch(fetchMyInfo())} />;
  }

  const expandedMemberData = expandMemberData(member);
  const { membershipLevel } = expandedMemberData || {};

  const { isInitial } = memberLevelCheckBuilder(membershipLevel);
  const labels = cleanedUpSpecialities.map((speciality) => speciality.label);
  surveyData.pages[2].elements[4].choices = labels;

  return (
    <SurveyContainer>
      <Survey
        model={surveyData}
        onSubmit={onSubmit}
        data={expandedMemberData}
        mode={isInitial() ? 'display' : undefined}
        isSinglePage={isInitial()}
      />
    </SurveyContainer>
  );
};

export default Application;
