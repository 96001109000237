import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { H2 } from 'masterpiece-ui';
import { Button } from '@allthingswww/client-act-shared';
import { useSelector } from 'react-redux';
import useForm from '../../../functions/utils/useForm';
import Plan from '../Plan';
import { getAllProducts } from '../../redux/stripeProducts';
import { getMyInfo } from '../../redux/member';

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const Para = styled.p`
  width: 70%;
  margin: 0 auto;
  padding: 0.5rem 0rem;
  color: inherit;
  line-height: 1.5rem;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 5rem;
  margin-top: 3rem;
  padding-left: -3rem;
`;

const PurchasePlanForm = () => {
  const allProducts = useSelector(getAllProducts);
  const myInfo = useSelector(getMyInfo);
  const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

  const { formData, setFormData, onSubmit } = useForm({
    selectedPlan: allProducts[0],
  }, (chosenPlan) => {
    navigate('/members/purchase-membership-checkout', { state: chosenPlan });
  });

  if (myInfo.isMemberShipValid) {
    navigate('/members/manage-subscriptions');
  }

  const { selectedPlan } = formData;

  return (
    <FlexVertical>
      <FlexHorizontal>
        {allProducts.map((plan) => (
          <Plan
            onClick={setFormData}
            data={plan}
            key={plan.id}
            selectedItem={formData}
            isSelected={plan.id === selectedPlan.id}
          >
            <H2>{plan.name}</H2>
            <Para>{plan.description}</Para>
            <Para>{Currency.format(plan.price / 100)}</Para>
          </Plan>
        ))}
      </FlexHorizontal>
      <ButtonWrapper>
        <Button.Button variant handleClick={onSubmit}>Checkout</Button.Button>
      </ButtonWrapper>
    </FlexVertical>
  );
};

export default PurchasePlanForm;
