import React from 'react';
import styled from 'styled-components';
import { Button } from '@allthingswww/client-act-shared';

const DefaultMessage = styled.div`

margin: 1rem 0;

`;

export default ({
  className,
  action,
  message = (<DefaultMessage>Your request could not be completed</DefaultMessage>),
}) => (
  <div className={className}>
    {message}
    <Button.Button type="button" onClick={action}>Try again</Button.Button>
  </div>
);
