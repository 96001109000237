import React from 'react';

const useForm = (initialState = {}, handleSubmit) => {
  const [formData, setFormData] = React.useState(initialState);

  const onChange = (e, isCheckbox) => {
    if (isCheckbox) {
      return setFormData({ ...formData, [e.target.name]: e.target.checked });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  }

  return { formData, onChange, onSubmit, setFormData };
}

export default useForm;